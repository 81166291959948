import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import GlobalStyles from './src/components/globalStyles';

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    {element}
  </PrismicProvider>
)

export const wrapPageElement = ({ element, ...restProps }, ...args) => {
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  );
};