exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-docs-tsx": () => import("./../../../src/templates/docs.tsx" /* webpackChunkName: "component---src-templates-docs-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-overleaf-tsx": () => import("./../../../src/templates/overleaf.tsx" /* webpackChunkName: "component---src-templates-overleaf-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/privacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-recommendations-tsx": () => import("./../../../src/templates/recommendations.tsx" /* webpackChunkName: "component---src-templates-recommendations-tsx" */),
  "component---src-templates-tma-tsx": () => import("./../../../src/templates/tma.tsx" /* webpackChunkName: "component---src-templates-tma-tsx" */)
}

