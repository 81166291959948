import React, { createContext } from "react";
import { useInView } from 'react-intersection-observer';

// const initialState = {
//   openAcc: false,
// };

// const actions = {
//   SET_ACC: "SET_ACC",
// };

// const reducer = (state, action) => {
//     switch (action.type) {
//       case actions.SET_ACC:
//         return { ...state, openMenu: action.value }
//       default:
//         return state
//     }
//   }

const GlobalContext = createContext(undefined);

const GlobalContextProvider = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const { ref, inView, entry } = useInView({
        /* Optional options */
        rootMargin: "500px",
        threshold: 0,
      });
    return (
        <GlobalContext.Provider value={{
        open, setOpen, 
        open1, setOpen1,
        open2, setOpen2,
        open3, setOpen3,
        open4, setOpen4,
        open5, setOpen5,
        ref, inView, 
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export { GlobalContextProvider as default, GlobalContext }