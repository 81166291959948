import { createGlobalStyle } from 'styled-components';
// import { ThemeProps } from '../particles/features/themes/themes';

// type GlobalThemeProps = {
//     theme: ThemeProps;
// };

const GlobalStyles = createGlobalStyle`
    /* @import url("https://use.typekit.net/aur2qos.css"); // ignorier dies line. alles schon bei react-helmet */

    :root {
      //dark-mode
      --dark-background: #1A1B27;
      --dark-text: #F5F5F7;
      --dark-ref: #000111;
  
      //light-mode
      --light-background: #f1e2cf; 
      --light-text: black;
      --light-ref: #e2e2e2;

      // margins

      --h1-margin: 3rem 0;
      --p-line-height: 1.75rem;

      --gap: 1rem;

      --top-margin: 10.5rem;
    }

    :target {
    scroll-margin-top: 25rem;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    // Reset margins
  
    * {
        margin: 0;
    }

    html, body {
        /* height: 100%; */
        font-size: 16px;
        /* background-color: var(--light-background); */
        /* background-color: rgb(240, 242, 255); */
        /* background: linear-gradient(#DEDDF0, #B6BCF2); */

    }

    /*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
    */

    body {
        scroll-behavior: smooth;
        line-height: 1.5;
        // min-height: 100vh; // optional
        /* -webkit-font-smoothing: antialiased;
        font-family: acumin-pro, sans-serif;
        font-weight: 500;
        font-style: normal; */
    }

    /*
    6. Improve media defaults
    */

    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }

    input, button, textarea, select {
        font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    // remove animations for people that turned them off

    @media (prefers-reduced-motion: reduce) {
        *,
        *::befoe,
        *::after {
            animation-duration: 0.01ms !important;
        }
    }

    h1 {
        font-size: 3.375rem;
        color: black;
    }

    li {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`;

export default GlobalStyles;