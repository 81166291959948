import * as React from 'react';
import GlobalContextProvider from '../particles/context/globalContext';

interface LayoutProps {
    
}
 
const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
    return ( 
    <>
    {/* Header */}
    <GlobalContextProvider>
    {children}
    </GlobalContextProvider>
    {/* Footer */}
    </> );
}
 
export default Layout;